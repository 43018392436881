import React, { useEffect, useState } from 'react';
import Modal from '../../components/modal/Modal';
import withAuth from '../../utils/IsAuth';
import BookLesson from '../../components/BookLesson';
import api from '../../utils/api';
import { Subject } from '../../model/registration/SchoolSelection';
import FamilyCalendar from '../../components/calendar/FamilyCalendar';
import { EventInput } from '@fullcalendar/core';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';

interface Props {}

interface Lesson {
  id: number;
  start_time: string;
  end_time: string;
  location: string;
  subject: Subject;
  teacher: {
    first_name: string;
    last_name: string;
  };
  student: {
    first_name: string;
    last_name: string;
  };
  size: number;
  status: string;
  lesson_order_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

const FamilyHome: React.FC<Props> = () => {
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [lessonsEvent, setLessonsEvent] = useState<EventInput[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { setLoading } = useLoading();
  const { setError } = useError();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    setLoading(true);
    api.get('lesson')
      .then((response) => {
        const res = response.data.lessons;
        setLessons(res);
        const lessonsCalendar: EventInput[] = res.map((lesson) => ({
          id: lesson.id.toString(),
          title: `${lesson.student.first_name} ${lesson.student.last_name}`,
          start: new Date(lesson.start_time),
          end: new Date(lesson.end_time),
          display: 'block',
          classNames: ['cursor-pointer', 'bg-green-200', 'border-green-500'],
          extendedProps: {
            student: lesson.student,
            teacher: lesson.teacher,
            subject: lesson.subject,
            location: lesson.location,
            customHtml: (
              <div className="flex flex-col text-black font-bold mt-1">
                <p>
                  {lesson.teacher.first_name} {lesson.teacher.last_name}
                </p>
                <p>
                  {lesson.subject.name} - {lesson.location}
                </p>
              </div>
            ),
          },
        }));
        setLessonsEvent(lessonsCalendar);
      })
      .catch((error) => {
        console.error('Error fetching lessons:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  return (
    <>
      <div className="max-w-5xl p-4 bg-white rounded-xl mx-2 sm:mx-auto h-full">
        <div className="flex flex-col items-center justify-center gap-y-4 h-full">
          <div>
            <h3 className="text-fpcred">Le mie lezioni</h3>
          </div>
          <div>
            <button className="py-2 px-3 bg-fpcred text-white rounded-lg" onClick={openModal}>
              Nuova lezione
            </button>
          </div>
          <div className="flex flex-col gap-y-3 w-full bg-white p-4 rounded-lg h-5/6">
            <FamilyCalendar lessonsEvent={lessonsEvent} />
          </div>
        </div>
      </div>
      <Modal open={isOpen} closeModal={closeModal}>
        <BookLesson />
      </Modal>
    </>
  );
};

export default withAuth(FamilyHome);

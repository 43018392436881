import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, EventInput } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import useScreenWidth from '../../hooks/useScreenWidth';

interface CalendarProps {
  lessonsEvent: EventInput[];
}

const BookedLessonsCalendar: React.FC<CalendarProps> = ({ lessonsEvent }) => {
  const [render, setRender] = useState(false);
  const [initialView, setInitialView] = useState<string>('timeGridWeek');
  const screenWidth = useScreenWidth();

  const today = new Date(Date.now());
  const start = new Date(new Date().setDate(today.getDate() - 30));
  const maxDate = new Date(new Date().setDate(today.getDate() + 30));

  useEffect(() => {
    setRender(true);
  }, []);

  useEffect(() => {
    if(screenWidth < 1024) {
      setInitialView('timeGridDay');
    } else {
      setInitialView('timeGridWeek');
    }
  }, [screenWidth]);

  const handleDateClick = (arg: any) => {
    if (arg.view.type === 'dayGridMonth' || arg.view.type === 'dayGridYear') {
      let calendarApi = arg.view.calendar;
      calendarApi.changeView('timeGridDay', arg.date);
    }
  };

  const selectAllow = (selectInfo: DateSelectArg) => {
    if (
      lessonsEvent.some(
        (lesson) =>
          selectInfo.start >= new Date(lesson.start_date_time) &&
          selectInfo.end <= new Date(lesson.end_date_time) &&
          selectInfo.end.getTime() - selectInfo.start.getTime() <= 30 * 60 * 1000
      )
    ) {
      return true;
    }
    return false;
  };


  const renderEventContent = (eventInfo) => {
    return (
      eventInfo.event.extendedProps.customHtml
    );
  };
  

  return (
    <>
      {render && (
        <FullCalendar
          key={initialView}
          selectAllow={selectAllow}
          // eventClick={(info: EventClickArg) => manageEventClick(info)}
          locale={itLocale}
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView={initialView}
          headerToolbar={{
            start: 'prev',
            center: 'title',
            end: 'next',
          }}
          editable={false}
          eventDragMinDistance={99}
          selectable={true}
          selectMirror={true}
          weekends={true}
          validRange={{ start: start, end: maxDate }}
          events={lessonsEvent}
          // select={(selectInfo: DateSelectArg) => selectDate(selectInfo)}
          dateClick={handleDateClick}
          allDaySlot={false}
          height={'100%'}
          eventContent={renderEventContent}
          slotMinTime={'07:00:00'}
          slotMaxTime={'22:00:00'}
        />
      )}
    </>
  );
};

export default BookedLessonsCalendar;

import { EventInput } from '@fullcalendar/core';
import React, { useState, useEffect } from 'react';

interface AddAvailabilityModalProps {
  selectedEvent: EventInput;
  handleTimeChange: (e: React.ChangeEvent<HTMLSelectElement>, type: string) => void;
  closeModal: () => void;
  handleCreate: (availability: any) => void;
  generateTimeOptions: (date: Date | string, type: string, startTime?: string) => JSX.Element[];
}

const AddAvailabilityModal: React.FC<AddAvailabilityModalProps> = ({
  selectedEvent,
  handleTimeChange,
  closeModal,
  handleCreate,
  generateTimeOptions,
}) => {
  const [recurrencyEnabled, setRecurrencyEnabled] = useState<boolean>(false);
  const [recurrencyType, setRecurrencyType] = useState<string>('daily');
  const [recurrencyEndDate, setRecurrencyEndDate] = useState<string>('');
  const [locations, setLocations] = useState({
    headquarter: false,
    home: false,
    home_from_headquarter: false,
    online: true,
  });
  const formatTime = (date: Date | string): string => {
    const d = new Date(date);
    return d.toTimeString().slice(0, 5);
  };
  const [onlyConsecutiveLessons, setOnlyConsecutiveLessons] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<string>(formatTime(selectedEvent.start as string));
  const [endTime, setEndTime] = useState<string>(formatTime(new Date(new Date(selectedEvent.start.toString()).getTime() + 60 * 60 * 1000).toISOString()));


  const formatDateTime = (date: Date | string): string => {
    const d = new Date(date);
    const yyyy = d.getFullYear();
    const mm = (`0${d.getMonth() + 1}`).slice(-2);
    const dd = (`0${d.getDate()}`).slice(-2);
    const hh = (`0${d.getHours()}`).slice(-2);
    const mi = (`0${d.getMinutes()}`).slice(-2);
    const ss = (`0${d.getSeconds()}`).slice(-2);
    return `${yyyy}-${mm}-${dd} ${hh}:${mi}:${ss}`;
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>, location: string) => {
    const newLocations = { ...locations, [location]: e.target.checked };
    setLocations(newLocations);
    checkAtLeastOneLocation(newLocations);
  };

  const checkAtLeastOneLocation = (locations: any) => {
    const atLeastOneSelected = Object.values(locations).some((value) => value);
    setIsSaveDisabled(!atLeastOneSelected);
  };

  const handleStartTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newStartTime = e.target.value;
    setStartTime(newStartTime);
    handleTimeChange(e, 'start');
  };

  const handleEndTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newEndTime = e.target.value;
    setEndTime(newEndTime);
    handleTimeChange(e, 'end');
  };

  useEffect(() => {
    checkAtLeastOneLocation(locations);
  }, []);

  const submit = () => {
    handleCreate({
      start_date_time: selectedEvent.start,
      end_date_time: selectedEvent.end,
      locations: {
        online: {
          enabled: locations.online,
          warning_time: 0
        },
        home: {
          enabled: locations.home,
          warning_time: 0
        },
        headquarter: {
          enabled: locations.headquarter,
          warning_time: 0
        },
        home_from_headquarter: {
          enabled: locations.home_from_headquarter,
          warning_time: 0
        },
      },
      only_consecutive_lessons: onlyConsecutiveLessons,
      recurrency: recurrencyEnabled
        ? {
            type: recurrencyType,
            end_date: recurrencyEndDate,
          }
        : null,
    });
  };

  if (!selectedEvent) return null;

  const isTimeValid = () => {
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    const diff = (end.getTime() - start.getTime()) / (1000 * 60 * 60);
    return diff >= 1;
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Aggiungi disponibilità</h3>
          <div className="mt-2 px-7 py-3">
            <form>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="start">
                  Orario Inizio
                </label>
                <select
                  id="start"
                  name="start"
                  value={startTime}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={handleStartTimeChange}>
                  {generateTimeOptions(selectedEvent.start as string, 'start')}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="end">
                  Orario Fine
                </label>
                <select
                  id="end"
                  name="end"
                  value={endTime}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={handleEndTimeChange}>
                  {generateTimeOptions(selectedEvent.end as string, 'end', startTime)}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="recurrency-toggle">
                  Abilita Ricorrenza
                </label>
                <input
                  type="checkbox"
                  id="recurrency-toggle"
                  name="recurrency-toggle"
                  checked={recurrencyEnabled}
                  onChange={() => setRecurrencyEnabled(!recurrencyEnabled)}
                  className="mr-2 leading-tight"
                />
                <span className="text-sm text-gray-600">Abilita/Disabilita Ricorrenza</span>
              </div>

              {recurrencyEnabled && (
                <>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="recurrency-type">
                      Tipo di Ricorrenza
                    </label>
                    <select
                      id="recurrency-type"
                      name="recurrency-type"
                      value={recurrencyType}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      onChange={(e) => setRecurrencyType(e.target.value)}
                    >
                      <option value="daily">Giornaliera</option>
                      <option value="weekly">Settimanale</option>
                      <option value="monthly">Mensile</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="recurrency-end-date">
                      Data di Fine Ricorrenza
                    </label>
                    <input
                      type="date"
                      id="recurrency-end-date"
                      name="recurrency-end-date"
                      value={recurrencyEndDate}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      onChange={(e) => setRecurrencyEndDate(e.target.value)}
                    />
                  </div>
                </>
              )}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="recurrency-toggle">
                  Lezioni Consecutive
                </label>
                <input
                  type="checkbox"
                  id="recurrency-toggle"
                  name="recurrency-toggle"
                  checked={onlyConsecutiveLessons}
                  onChange={() => setOnlyConsecutiveLessons(!onlyConsecutiveLessons)}
                  className="mr-2 leading-tight"
                />
                <span className="text-sm text-gray-600">Abilita/Disabilita Lezioni consecutive</span>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Località</label>
                <div className="flex flex-wrap justify-between">
                  <div className="w-1/2 mb-4">
                    <input
                      type="checkbox"
                      id="location-headquarter"
                      name="location-headquarter"
                      checked={locations.headquarter}
                      onChange={(e) => handleLocationChange(e, 'headquarter')}
                      className="mr-2 leading-tight"
                    />
                    <label htmlFor="location-headquarter" className="text-sm text-gray-600">
                      In sede
                    </label>
                  </div>
                  <div className="w-1/2 mb-4">
                    <input
                      type="checkbox"
                      id="location-home"
                      name="location-home"
                      checked={locations.home}
                      onChange={(e) => handleLocationChange(e, 'home')}
                      className="mr-2 leading-tight"
                    />
                    <label htmlFor="location-home" className="text-sm text-gray-600">
                      A domicilio
                    </label>
                  </div>
                  <div className="w-1/2 mb-4">
                    <input
                      type="checkbox"
                      id="location-home-from-headquarter"
                      name="location-home-from-headquarter"
                      checked={locations.home_from_headquarter}
                      onChange={(e) => handleLocationChange(e, 'home_from_headquarter')}
                      className="mr-2 leading-tight"
                    />
                    <label htmlFor="location-home-from-headquarter" className="text-sm text-gray-600">
                      A domicilio vicino alla sede
                    </label>
                  </div>
                  <div className="w-1/2 mb-4">
                    <input
                      type="checkbox"
                      id="location-online"
                      name="location-online"
                      checked={locations.online}
                      onChange={(e) => handleLocationChange(e, 'online')}
                      className="mr-2 leading-tight"
                    />
                    <label htmlFor="location-online" className="text-sm text-gray-600">
                      Online
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="items-center px-4 py-3">
            <button
              className={`px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300 ${
                isSaveDisabled || !isTimeValid() ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={submit}
              disabled={isSaveDisabled || !isTimeValid()}
            >
              Crea
            </button>
            <button
              className="mt-2 px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
              onClick={closeModal}
            >
              Indietro
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAvailabilityModal;


